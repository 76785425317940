export const config = {
  apiKey: 'AIzaSyAAk-J7mXx8uOCgfQnd7gIwO5rW9mFodNo',
  authDomain: 'mint-dev-532af.firebaseapp.com',
  databaseURL: 'https://mint-dev-532af.firebaseio.com',
  projectId: 'mint-dev-532af',
  storageBucket: 'mint-dev-532af.appspot.com',
  messagingSenderId: '190771214781',
  appId: '1:190771214781:web:911ac643ece3b2ae303ad4',
  measurementId: 'G-4FRM2VFKNG',
  region: 'europe-west1',
  amalfiApplicationId: '1c7b1603-1662-44a2-8f59-d0d82882b3d8',
  amalfiTenant: 'c9520924-d349-4ca7-b5e9-d2956e0812f4',
  amalfiLoginKey: 'qn_swhO7AwxD62lH9vB92PVlwwtj0nt17jGkxjr2LRg',
  loginUrl: 'https://login.mint.amalfianalytics.com/login',
  refreshUrl: 'https://login.mint.amalfianalytics.com/refresh'
}

export default config
